import { LabelColor } from 'src/components/label';
import { IImportListingItem } from 'src/store/dashboard/slices/importTableSlice';
import { IMyShopsState } from 'src/store/dashboard/slices/myShopsSlice';

//--COLUMNS

export interface DataGridCreatedDateColumnInterface {
  itemData: {
    added: string;
    marketplaceSources: IMarketplaceSources;
    id: string;
    title: string;
    image: string;
  };
}
export interface DataGridImageColumnInterface {
  url: string;
}
export interface DataGridRefsColumnInterface {
  marketplaceSources: IMarketplaceSources;
}
export interface DataGridNameColumnInterface {
  title: string;
  id?: string;
}
export interface DataGridStatusColumnInterface {
  status: string;
  rowData?: any;
  statusText?: string;
}
export interface DataGridSkuColumnInterface {
  sku: string;
}
export interface DataGridPriceColumnInterface {
  price: string;
  currency?: {
    code: string;
    symbol: string;
  };
}
export interface DataGridGroupsColumnInterface {
  groups: { name: string; _id: string; userId: string }[];
  rowData?: any;
}
export interface IInventoryTag {
  name: string;
  color: LabelColor;
  _id: string;
}
export interface DataGridTagsColumnInterface {
  tags: IInventoryTag[];
  rowData?: any;
}

export interface ICrosslistFilter {
  [key: string]: string | number | boolean;
}

//--SLICES

export interface IInventoryRequest {
  page: number;
  rowsPerPage: number;
  orderBy: string | null | undefined;
  order: string | null | undefined;
  filter: any;
  searchValue: string | null | undefined;
  refresh?: boolean;
}

export interface IMarketplaceSources {
  [key: string]: {
    added: number;
    id: string;
    listingLink: string;
    platform: string;
    updated: number;
    closetName?: string;
  };
}

export interface IInventoryItemData {
  added: number;
  category: string;
  groups: any[];
  image: string;
  price: number;
  refs: IMarketplaceSources;
  sku: string;
  status: string;
  tags: any[];
  listingTags: any[];
  title: string;
  updated: number;
  userId: string;
  _id: string;
  id?: string;
  description?: string;
  condition?: string;
  brand?: string;
  color?: string;
  inertialNotes?: string;
}
export interface IInventoryPopoverData {
  image: string;
  marketplaceSources: IMarketplaceSources;
  title: string;
  _id: string;
  id?: string;
}

export interface IInventoryDeleteRequestPayload {
  ids: string[];
  getData: IInventoryRequest;
  deleteMessage?: string;
}
export interface IInventoryGroupActionPayload {
  groupId?: string;
  items: string[];
  getData?: IInventoryRequest;
  groupName?: string;
  customMessage?: string;
  showCustomMessage?: boolean;
  isFromEditPage?: boolean;
}
export interface IInventoryTagActionPayload {
  tagIds: string[];
  items?: string[];
  itemId?: string;
  getData?: IInventoryRequest;
  tagName?: string;
  customMessage?: string;
  showCustomMessage?: boolean;
  isFromEditPage?: boolean;
}
export interface IInventoryUpdateStatusRequestPayload {
  status: string;
  items: string[];
  getData: IInventoryRequest;
}
export interface IInventoryMergeListingsRequestPayload {
  items: string[];
  getData: IInventoryRequest;
}

export interface ISelectedListing {
  [key: string]: string | number | any[] | IMarketplaceSources | undefined;
  added?: number;
  category?: string;
  groups?: any[];
  image?: string;
  price?: number;
  refs?: IMarketplaceSources;
  sku?: string;
  status?: string;
  tags?: any[];
  hashtags?: string[];
  listingTags?: any[];
  title?: string;
  updated?: number;
  userId?: string;
  _id?: string;
  id?: string;
  description?: string;
  condition?: string;
  brand?: string;
  color?: string[];
  notes?: string;
}
export interface IBulkActionsSelectedListings {
  [key: string]: string | number | any[] | IMarketplaceSources | undefined;
  image: string;
  marketplaceSources: IMarketplaceSources;
  sku: string;
  title: string;
  _id: string;
  groups: any[];
  tags: any[];
}
export interface IEditListingActionPayload {
  id: string;
  editData: {
    groups?: { name: string; id: string }[] | string;
    status?: string;
    listingTags?: { name: string; id: string; color: string }[];
    inertialNotes?: string;
  };
  getData?: IInventoryRequest;
  editType?: 'edit' | 'saveLink' | 'removeLink';
  hasNewGroup?: boolean;
  hasNewTag?: boolean;
  showCustomMessage?: boolean;
}
export interface IGetListingsPayloadData {
  importPlatform: string;
  importNextPageID?: any;
  importSearchText?: string;
  shop?: string;
  myShopsState: IMyShopsState;
}
export interface IGetListingsResponseData {
  hasMore: boolean;
  nextPage: any;
  listings: IImportListingItem[];
}
export interface IImportListingsPayload {
  selectedListings: IImportListingItem[];
  importPlatform: string;
  getInventoryData?: IInventoryRequest;
  myShopsState: IMyShopsState;
}

export interface IListingSources {
  loginStatus: boolean;
  platformName: string;
  listingId: string;
}

export enum CurrentInventoryActionEnum {
  SEARCH = 'search',
  CLEAR = 'clear',
  EMPTY = '',
}
