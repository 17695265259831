import React from 'react';
import ManageSubscriptionsDialog from '../../../mainComponents/OrdersPage/others/ManageSubscriptionsDialog';

const OrdersDialogs = () => (
  <>
 
    <ManageSubscriptionsDialog />
  </>
);

export default OrdersDialogs;
