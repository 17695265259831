import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, TableContainer } from '@mui/material';

import Scrollbar from 'src/components/scrollbar';
import { IRootState } from 'src/store';
import { IMyShopsTableRow, IPlatforms } from 'src/store/dashboard/slices/myShopsSlice';
import RowMenuPopover from './tableItems/BrowserRowMenuPopover';
import TableHeadCustom from './tableItems/TableHeadCustom';

import BrowserTableRow from './tableItems/BrowserTableRow';
// import { useFlags } from 'flagsmith/react';

export const getPlatformIcon = (platformName: string) => `/icons/editLinkIcons/${platformName}.svg`;

export default function MyShopsTable() {
  const {
    mercari,
    [IPlatforms.POSHMARKUS]: poshmarkUS,
    [IPlatforms.POSHMARKCA]: poshmarkCA,
    facebook,
    shopify,
    etsy,
    grailed,
    depop,
  } = useSelector((state: IRootState) => state.myShops);

  // const flags = useFlags(['poshmarkautomation', 'oauth']);
  // const oAuthEnabled = flags.oauth.enabled;
  const oAuthEnabled = false;

  const SHOPS_DATA: IMyShopsTableRow[] = [
    {
      platformName: IPlatforms.MERCARI,
      name: 'Mercari',
      icon: getPlatformIcon('mercari'),
      shop: 'Mercari Shop',
      isConnected: mercari.isLoggedIn,
      isLoading: mercari.isLoading,
      username: mercari.username,
      connectionLink: 'https://www.mercari.com/login/',
    },
    {
      platformName: IPlatforms.POSHMARKUS,
      name: 'Poshmark (US)',
      icon: getPlatformIcon('poshmark'),
      shop: 'Poshmark (US) Shop',
      isConnected: poshmarkUS.isLoggedIn,
      isLoading: poshmarkUS.isLoading,
      username: poshmarkUS.username,
      connectionLink: 'https://poshmark.com/login',
    },
    {
      platformName: IPlatforms.POSHMARKCA,
      name: 'Poshmark (CA)',
      icon: getPlatformIcon('poshmark'),
      shop: 'Poshmark (CA) Shop',
      isConnected: poshmarkCA.isLoggedIn,
      isLoading: poshmarkCA.isLoading,
      username: poshmarkCA.username,
      connectionLink: 'https://poshmark.ca/login',
    },
    {
      platformName: IPlatforms.DEPOP,
      name: 'Depop',
      icon: getPlatformIcon('depop'),
      shop: 'Depop Shop',
      isConnected: depop.isLoggedIn,
      isLoading: depop.isLoading,
      username: depop.username,
      connectionLink: 'https://www.depop.com/login/',
    },
    {
      platformName: IPlatforms.GRAILED,
      name: 'Grailed',
      icon: getPlatformIcon('grailed'),
      shop: 'Grailed Shop',
      isConnected: grailed.isLoggedIn,
      isLoading: grailed.isLoading,
      username: grailed.username,
      connectionLink: 'https://www.grailed.com/',
    },
    {
      platformName: IPlatforms.FACEBOOK,
      name: 'Facebook',
      icon: getPlatformIcon('facebook'),
      shop: 'Facebook Shop',
      isConnected: facebook.isLoggedIn,
      isLoading: facebook.isLoading,
      username: facebook.username,
      connectionLink: 'https://www.facebook.com/login/?next=%2Fmarketplace%2F',
    },
    {
      platformName: IPlatforms.ETSY,
      name: 'Etsy',
      icon: getPlatformIcon('etsy'),
      shop: 'Etsy Shop',
      isConnected: etsy.isLoggedIn,
      isLoading: etsy.isLoading,
      username: etsy.username,
      connectionLink: 'https://www.etsy.com/signin',
    },
    {
      platformName: IPlatforms.SHOPIFY,
      name: 'Shopify',
      icon: getPlatformIcon('shopify'),
      shop: 'Shopify Shop',
      isConnected: shopify.isLoggedIn,
      isLoading: shopify.isLoading,
      username: shopify.username,
      connectionLink: 'https://accounts.shopify.com/store-login',
    },
  ];

  const TABLE_HEAD = [
    { id: 'marketplace', label: 'Marketplace' },
    { id: 'shop', label: 'Shop', align: 'center' },
    { id: 'status', label: 'Status', align: 'center' },
    { id: 'autoDelist', label: 'Auto Delist', align: 'center' },
    { id: 'actions', label: 'Actions', align: 'center' },
  ];

  const filteredShopsData = SHOPS_DATA.filter((shop) => {
    if (!oAuthEnabled) {
      return true; // If oAuth is not enabled, include all
    }
    // If oAuth is enabled, include all except eBay (AU, CA, US) and Shopify
    return ![IPlatforms.SHOPIFY].includes(shop.platformName as IPlatforms);
  });

  return (
    <TableContainer sx={{ overflow: 'unset' }}>
      <Scrollbar>
        <Table sx={{ width: '100%' }}>
          <TableHeadCustom headLabel={TABLE_HEAD} />

          <TableBody>
            {filteredShopsData.map((row) => (
              <BrowserTableRow key={row.name} row={row} />
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      <RowMenuPopover />
    </TableContainer>
  );
}
