import {
  getGridNumericOperators,
  GridColumns,
  getGridSingleSelectOperators,
} from '@mui/x-data-grid-pro';
import { getNumericBetweenOperator } from 'src/pages/dashboard/Inventory/helpers/getNumericBetweenOperator';
import {
  DataGridNameColumn,
  DataGridMarketplaceColumn,
  DataGridStatusColumn,
  DataGridPriceColumn,
  DataGridImageColumn,
} from './DataGridColumns';
import { getMarketplaceDisplayName, Marketplace } from '@beta.limited/primelister';
import { ITagData } from 'src/store/dashboard/slices/groupsAndTagsSlice';

export const orderPlatformOptions = Object.values(Marketplace)
  .map((marketplace) => ({
    value: marketplace,
    label: getMarketplaceDisplayName(marketplace),
  }))
  .filter((option) => option.label)
  .sort((a, b) => a.label.localeCompare(b.label));

export const gridColumns = () => {
  const { numericBetweenOperator } = getNumericBetweenOperator();

  const priceFilterOperators = [
    ...getGridNumericOperators().slice(0, 6),
    numericBetweenOperator,
    ...getGridNumericOperators().slice(6, 8),
  ];

  //--is exactly
  const isExactlyOperator = getGridSingleSelectOperators().find(
    (filter) => filter.value?.toLowerCase() === 'isanyof'
  );
  isExactlyOperator!.label = 'is exactly';
  isExactlyOperator!.value = 'isExactly';

  //-- has all of
  const hasAllOfOperator = getGridSingleSelectOperators().find(
    (filter) => filter.value?.toLowerCase() === 'isanyof'
  );
  hasAllOfOperator!.label = 'has all of';
  hasAllOfOperator!.value = 'hasAllOf';

  //-- has none of
  const hasNoneOfOperator = getGridSingleSelectOperators().find(
    (filter) => filter.value?.toLowerCase() === 'isanyof'
  );
  hasNoneOfOperator!.label = 'has none of';
  hasNoneOfOperator!.value = 'hasNoneOf';

  //-- has any of
  const hasAnyOfOperator = getGridSingleSelectOperators().find(
    (filter) => filter.value?.toLowerCase() === 'isanyof'
  );
  hasAnyOfOperator!.label = 'has any of';
  hasAnyOfOperator!.value = 'hasAnyOf';

  const platformOperators = [
    hasAnyOfOperator,
    hasAllOfOperator,
    isExactlyOperator,
    hasNoneOfOperator,
  ];

  const columns: GridColumns = [
    {
      field: 'image',
      headerName: 'Image',
      pinnable: false,
      hideable: false,
      width: 64,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      filterable: false,
      valueGetter: (params) => params.value,
      renderCell: (params) => <DataGridImageColumn url={params.value} />,
    },
    {
      field: 'title',
      headerName: 'Name',
      pinnable: false,
      hideable: false,
      filterable: false,
      flex: 2,
      minWidth: 200,
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params) => params.value,
      renderCell: (params) => (
        <DataGridNameColumn title={params.value.title} id={params.value._id} />
      ),
    },
    {
      field: 'soldPrice',
      type: 'number',
      headerName: 'Price',
      pinnable: false,
      hideable: false,
      flex: 1,
      minWidth: 100,
      filterable: false,
      headerAlign: 'center',
      align: 'center',
      filterOperators: priceFilterOperators,
      valueGetter: (params) => params.row.soldPrice,
      renderCell: (params) => (
        <DataGridPriceColumn price={params.row.soldPrice} currency={params.row.currency} />
      ),
    },
    {
      field: 'marketplace',
      type: 'singleSelect',
      valueOptions: orderPlatformOptions.map((option) => option.label),
      headerName: 'Marketplace',
      pinnable: false,
      hideable: false,
      flex: 1,
      filterable: false,
      minWidth: 120,
      //@ts-ignore
      filterOperators: platformOperators,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.value,
      renderCell: (params: any) => (
        <DataGridMarketplaceColumn rowData={params.row} marketplace={params.value} />
      ),
    },
    // {
    //   field: 'statusText',
    //   type: 'singleSelect',
    //   valueOptions: ['In Transit', 'Order Cancelled', 'Order Complete'],
    //   headerName: 'Status',
    //   pinnable: false,
    //   cellClassName: 'datagrid-cell-status',
    //   hideable: false,
    //   flex: 0.8,
    //   minWidth: 90,
    //   headerAlign: 'center',
    //   align: 'center',
    //   valueGetter: (params) => params.value,
    //   renderCell: (params) => (
    //     <DataGridStatusColumn
    //       rowData={params.row}
    //       statusText={params.value}
    //       status={params.row.status}
    //     />
    //   ),
    // },
  ];
  return columns;
};
