import { useDispatch, useSelector } from 'react-redux';
import {
  TableRow,
  TableCell,
  IconButton,
  Avatar,
  Switch,
  CircularProgress,
  Tooltip,
} from '@mui/material';

import Iconify from 'src/components/iconify';
import { IRootState } from 'src/store';
import {
  IMyShopsTableRow,
  setIsBrowserTableActionsMenuOpen,
  setSelectedActionRow,
} from 'src/store/dashboard/slices/myShopsSlice';
import { LoadingButton } from '@mui/lab';
import { updateShopSettingsRequest } from 'src/store/dashboard/slices/myShopsSlice';
import { Automation, Marketplace, OrderJobType } from '@beta.limited/primelister';
import { getShopperId } from 'src/pages/dashboard/Inventory/mainComponents/ImportTable/importDataGrid/helpers';
import { useTrackOrdersSwitch } from 'src/hooks/useTrackOrdersSwitch';

// Define the supported marketplaces type
export type SupportedMarketplace = Marketplace.POSHMARK_US | Marketplace.POSHMARK_CA;

export const SUPPORTED_MARKETPLACES: SupportedMarketplace[] = [
  Marketplace.POSHMARK_US,
  Marketplace.POSHMARK_CA,
];

export default function BrowserTableRow({ row }: { row: IMyShopsTableRow }) {
  const dispatch = useDispatch();

  const { isBrowserTableActionsMenuOpen } = useSelector((state: IRootState) => state.myShops);
  const { isExtensionConnected } = useSelector((state: IRootState) => state.home);
  const myShops = useSelector((state: IRootState) => state.myShops);

  const openActionsMenu = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(
      setIsBrowserTableActionsMenuOpen(
        isBrowserTableActionsMenuOpen === null ? event.currentTarget : null
      )
    );
    dispatch(setSelectedActionRow(row));
  };
  const renderShopName = row.isConnected && row.username ? row.username : row.shop;

  const isConnected = row.isConnected && isExtensionConnected;

  const { isLoading, tooltipTitle, switchProps, shopSettings, isSupported } = useTrackOrdersSwitch({
    row,
    isConnected,
  });

  return (
    <TableRow>
      <TableCell sx={{ display: 'flex', alignItems: 'center', minHeight: '68px !important' }}>
        <Avatar src={row.icon} sx={{ width: 24, height: 24, mr: 1 }} />
        {row.name}
      </TableCell>
      <TableCell align="center">{renderShopName}</TableCell>
      <TableCell align="center">
        <LoadingButton
          loading={row.isLoading}
          variant="soft"
          color={isConnected ? 'success' : 'error'}
          sx={{ maxHeight: '24px !important', fontSize: '12px !important' }}
        >
          {isConnected ? 'Connected' : 'Unconnected'}
        </LoadingButton>
      </TableCell>

      <TableCell align="center">
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <Tooltip title={tooltipTitle} arrow>
            <span>
              <Switch
                {...switchProps}
                onChange={() => {
                  if (isConnected && shopSettings?.data && isSupported) {
                    const shopperId = getShopperId(row.platformName as Marketplace, myShops);
                    if (shopperId) {
                      dispatch(
                        updateShopSettingsRequest({
                          marketplace: row.platformName as Marketplace,
                          shop: shopperId,
                          settings: {
                            ...shopSettings.data.settings,
                            [OrderJobType.TRACK_ORDERS]: {
                              enabled:
                                !shopSettings.data.settings[OrderJobType.TRACK_ORDERS]?.enabled,
                            },
                          },
                        })
                      );
                    }
                  }
                }}
              />
            </span>
          </Tooltip>
        )}
      </TableCell>

      <TableCell align="center">
        <IconButton color="default" onClick={(event) => openActionsMenu(event)}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
