import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, TableContainer } from '@mui/material';
import { useEffect } from 'react';

import Scrollbar from 'src/components/scrollbar';
import { IRootState } from 'src/store';
import { IMyShopsTableRow, IPlatforms } from 'src/store/dashboard/slices/myShopsSlice';
import APIRowMenuPopover from './tableItems/APIRowMenuPopover';
import TableHeadCustom from './tableItems/TableHeadCustom';

import APITableRow from './tableItems/APITableRow';
import {
  IAPIMarketplaces,
  IMyShopsAPITable,
} from 'src/store/dashboard/slices/myShopsAPITableSlice';
import { ConnectionStatus } from 'src/store/automations/slices/myClosetSlice';
import { getConnectedEbayShop } from 'src/pages/dashboard/Inventory/mainComponents/ImportTable/importDataGrid/helpers';
import { getShopSettingsRequest } from 'src/store/dashboard/slices/myShopsSlice';
import { Marketplace } from '@beta.limited/primelister';

export default function APITable() {
  const dispatch = useDispatch();
  const myShopsAPITable = useSelector((state: IRootState) => state.myShopsAPITable);
  const eBayUS = myShopsAPITable[IAPIMarketplaces.EBAY as unknown as keyof IMyShopsAPITable];
  const getPlatformIcon = (platformName: string) => `/icons/editLinkIcons/${platformName}.svg`;

  useEffect(() => {
    const connectedEbayShop = getConnectedEbayShop(myShopsAPITable);
    if (connectedEbayShop?.shopName && connectedEbayShop.connectionStatus === ConnectionStatus.Connected) {
      dispatch(
        getShopSettingsRequest({
          marketplace: IPlatforms.EBAYUS as unknown as Marketplace,
          shop: connectedEbayShop.shopName
        })
      );
    }
  }, [dispatch, myShopsAPITable.allShops]);

  const ebayShopData = myShopsAPITable?.allShops?.find(
    (shop) =>
      shop.marketplace.includes('ebay') && shop.connectionStatus === ConnectionStatus.Connected
  );
  const SHOPS_DATA: IMyShopsTableRow[] = [
    // {
    //   platformName: IPlatforms.SHOPIFY,
    //   name: 'Shopify',
    //   icon: getPlatformIcon('shopify'),
    //   shop: 'Shopify Shop',
    //   isConnected: shopify.isLoggedIn,
    //   isLoading: shopify.isLoading,
    //   username: shopify.shopData?.shopName || 'Shopify Shop',
    //   connectionLink: 'primelisterTestStoreWithTestData.myshopify.com',
    // },
    {
      platformName: IPlatforms.EBAYUS,
      name: 'eBay',
      icon: getPlatformIcon('ebay'),
      shop: 'eBay Shop',
      isConnected: !!ebayShopData && ebayShopData?.connectionStatus === ConnectionStatus.Connected,
      isLoading: eBayUS.isLoading,
      username: ebayShopData?.shopName || 'eBay Shop',
      connectionLink: 'https://www.ebay.com/signin/',
    },
  ];
  const TABLE_HEAD = [
    { id: 'marketplace', label: 'Marketplace' },
    { id: 'shop', label: 'Shop', align: 'center' },
    { id: 'status', label: 'Status', align: 'center' },
    { id: 'autoDelist', label: 'Auto Delist', align: 'center' },
    { id: 'actions', label: 'Actions', align: 'center' },
  ];

  return (
    <TableContainer sx={{ overflow: 'unset' }}>
      <Scrollbar>
        <Table sx={{ width: '100%' }}>
          <TableHeadCustom headLabel={TABLE_HEAD} />
          <TableBody>
            {SHOPS_DATA.map((row) => (
              <APITableRow key={row.name} row={row} />
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      <APIRowMenuPopover />
    </TableContainer>
  );
}
