import { useDispatch, useSelector } from 'react-redux';
import {
  TableRow,
  TableCell,
  IconButton,
  Avatar,
  Switch,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { Automation, Marketplace, OrderJobType } from '@beta.limited/primelister';
import { getConnectedEbayShop } from 'src/pages/dashboard/Inventory/mainComponents/ImportTable/importDataGrid/helpers';
import { updateShopSettingsRequest } from 'src/store/dashboard/slices/myShopsSlice';

import Iconify from 'src/components/iconify';
import { IRootState } from 'src/store';
import {
  IMyShopsTableRow,
  setIsAPITableActionsMenuOpen,
  setSelectedActionRow,
} from 'src/store/dashboard/slices/myShopsSlice';
import { LoadingButton } from '@mui/lab';
import {
  SUPPORTED_MARKETPLACES,
  SupportedMarketplace,
} from '../../MyShopsBrowserTable/tableItems/BrowserTableRow';
import { useTrackOrdersSwitch } from 'src/hooks/useTrackOrdersSwitch';

export default function APITableRow({ row }: { row: IMyShopsTableRow }) {
  const dispatch = useDispatch();
  const { isAPITableActionsMenuOpen } = useSelector((state: IRootState) => state.myShops);
  const shopSettings = useSelector(
    (state: IRootState) => state.myShops.shopSettings[row.platformName]
  );
  const myShopsAPITable = useSelector((state: IRootState) => state.myShopsAPITable);

  const openActionsMenu = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(
      setIsAPITableActionsMenuOpen(isAPITableActionsMenuOpen === null ? event.currentTarget : null)
    );
    dispatch(setSelectedActionRow(row));
  };
  const { isConnected } = row;

  const renderShopName = isConnected ? row.username : row.shop;

  const { isLoading, tooltipTitle, switchProps, shopSettings: shopSettingsFromHook, isSupported } = useTrackOrdersSwitch({
    row,
    isConnected,
  });

  return (
    <TableRow>
      <TableCell sx={{ display: 'flex', alignItems: 'center', minHeight: '68px !important' }}>
        <Avatar src={row.icon} sx={{ width: 24, height: 24, mr: 1 }} />
        {row.name}
      </TableCell>
      <TableCell align="center">{renderShopName}</TableCell>
      <TableCell align="center">
        <LoadingButton
          loading={row.isLoading} //this will be changed with new integration
          variant="soft"
          color={isConnected ? 'success' : 'error'}
          sx={{ maxHeight: '24px !important', fontSize: '12px !important' }}
        >
          {isConnected ? 'Connected' : 'Unconnected'}
        </LoadingButton>
      </TableCell>

      <TableCell align="center">
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <Tooltip title={tooltipTitle} arrow>
            <span>
              <Switch
                {...switchProps}
                onChange={() => {
                  if (isConnected && shopSettingsFromHook?.data && isSupported) {
                    const connectedEbayShop = getConnectedEbayShop(myShopsAPITable);
                    if (connectedEbayShop?.shopName) {
                      dispatch(
                        updateShopSettingsRequest({
                          marketplace: row.platformName as Marketplace,
                          shop: connectedEbayShop.shopName,
                          settings: {
                            ...shopSettingsFromHook.data.settings,
                            [OrderJobType.TRACK_ORDERS]: {
                              enabled: !shopSettingsFromHook.data.settings[OrderJobType.TRACK_ORDERS]?.enabled,
                            },
                          },
                        })
                      );
                    }
                  }
                }}
              />
            </span>
          </Tooltip>
        )}
      </TableCell>

      <TableCell align="center">
        <IconButton
          className="connectToAPIButton"
          color="default"
          onClick={(event) => openActionsMenu(event)}
        >
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
