import { Card } from '@mui/material';

import OrdersTable from './OrdersPage/OrdersTable';
import OrdersDialogs from '../components/dialogs/orders/OrdersDialogs';

export default function OrdersCard() {
  return (
    <>
      <Card>
        <OrdersTable />
        <OrdersDialogs />
      </Card>
    </>
  );
}
