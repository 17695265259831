import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import axiosInstance from 'src/utils/axios';
import { crosslistEndpoints } from 'src/endpoints';

import {
  IOrdersRequest,
  IOrdersResponse,
  IOrderDetailRequest,
  IOrderDetailResponse,
} from 'src/pages/dashboard/Orders/types/dataGridInterfaces';
import { toast } from 'react-toastify';
import {
  ordersGetListFail,
  ordersGetListRequest,
  ordersGetListSuccess,
  setIsRefreshLoading,
  setLoadingEffect,
  setOrdersCount,
  orderDetailRequest,
  orderDetailSuccess,
  orderDetailFail,
  delistOrderRequest,
  delistOrderSuccess,
  delistOrderFail,
  IDelistOrderRequest,
} from '../slices/ordersSlice';
import { ActionSource, SubscriptionType } from '@beta.limited/primelister';
import { IUserActionQueueJobBase } from 'src/pages/dashboard/MyShops/types';

enum UserQueueJobAction {
  DELIST = 'Delist',
}

function* ordersGetListSaga(action: PayloadAction<IOrdersRequest>) {
  try {
    if (action.payload.refresh) {
      yield put(setIsRefreshLoading(true));
    }
    const response: AxiosResponse<IOrdersResponse> = yield call(() =>
      axiosInstance.get(
        crosslistEndpoints.orders.GET_ORDERS(
          action.payload.page,
          action.payload.rowsPerPage,
          action.payload.orderBy,
          action.payload.order,
          action.payload.filter,
          action.payload.searchValue ?? undefined
        )
      )
    );
    yield put(ordersGetListSuccess(response?.data.data.results));
    yield put(setOrdersCount(response.data.data.count));
    yield put(setLoadingEffect(false));
    if (action.payload.refresh) {
      yield put(setIsRefreshLoading(false));
      toast.success('Results successfully refreshed', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  } catch (error) {
    if (action.payload.refresh) {
      yield put(setIsRefreshLoading(false));
    }
    yield put(ordersGetListFail(error.message));
  }
}

function* orderDetailSaga(action: PayloadAction<IOrderDetailRequest>) {
  try {
    const response: AxiosResponse<IOrderDetailResponse> = yield call(() =>
      axiosInstance.get(crosslistEndpoints.orders.GET_ORDER_DETAIL(action.payload.id))
    );

    yield put(
      orderDetailSuccess({
        id: action.payload.id,
        data: response.data.data,
      })
    );
  } catch (error) {
    yield put(
      orderDetailFail({
        id: action.payload.id,
        error: error.message,
      })
    );
  }
}

function* delistOrderSaga(action: PayloadAction<IDelistOrderRequest>) {
  try {
    const { marketplace, shop, listings } = action.payload;

    const requestPayload = {
      action: UserQueueJobAction.DELIST,
      marketplace,
      shop: shop.toString(),
      listings,
      actionSource: ActionSource.WEB,
    };

    const response: AxiosResponse = yield call(() =>
      axiosInstance.post(crosslistEndpoints.inventory.GET_USER_ACTION_QUEUE_JOB(), requestPayload)
    );

    yield toast.promise(
      Promise.resolve(response),
      {
        pending: 'Adding delist tasks to queue...',
        success: 'Delist tasks added to queue successfully.',
        error: {
          render({ data }: any) {
            return data?.response?.data?.message || 'Something went wrong';
          },
        },
      },
      {
        position: 'top-center',
      }
    );

    yield put(delistOrderSuccess());
  } catch (error) {
    yield put(delistOrderFail(error.message));
    toast.error(error.message, {
      position: 'top-right',
    });
  }
}

export function* ordersModuleSaga() {
  yield all([
    takeLatest(ordersGetListRequest.type, ordersGetListSaga),
    takeLatest(orderDetailRequest.type, orderDetailSaga),
    takeLatest(delistOrderRequest.type, delistOrderSaga),
  ]);
}
