import { Typography } from '@mui/material';
import { DataGridNameColumnInterface } from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';
import { PATH_DASHBOARD } from 'src/routes/paths';

export default function DataGridNameColumn({ title, id }: DataGridNameColumnInterface) {
  return (
    <Typography variant="subtitle2" sx={{ whiteSpace: 'pre-wrap' }}>
      {title}
    </Typography>
  );
}
