import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { IRootState } from 'src/store';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useNavigate } from 'react-router-dom';
import { setIsManageSubscriptionsDialogOpen } from 'src/store/dashboard/slices/ordersSlice';

export default function ManageSubscriptionsDialog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isManageSubscriptionsDialogOpen, manageSubscriptionDialogData } = useSelector(
    (state: IRootState) => state.orders
  );

  const handleClose = () => dispatch(setIsManageSubscriptionsDialogOpen(false));

  const handleManageSubscriptions = () => {
    navigate(PATH_DASHBOARD.subscription.root);
    handleClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isManageSubscriptionsDialogOpen} onClose={handleClose}>
      <DialogTitle sx={{ pb: 2 }}>{manageSubscriptionDialogData?.shortMessage}</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>
        {manageSubscriptionDialogData?.message}
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleManageSubscriptions}
          sx={{ textTransform: 'none !important' }}
        >
          Manage Subscriptions
        </Button>
      </DialogActions>
    </Dialog>
  );
}
