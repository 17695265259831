// @mui
import { Tooltip, IconButton, Paper, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from 'src/components/iconify';
// components
import { IOrdersPopoverData } from '../../../types/dataGridInterfaces';

import { IRootState } from 'src/store';
import useIsDocumentLoaded from 'src/hooks/useIsDocumentLoaded';
import { getMarketplaceDisplayName, Marketplace } from '@beta.limited/primelister';

// ----------------------------------------------------------------------

export default function QuickActionsMenu({ newData }: { newData: IOrdersPopoverData }) {
  const { hasCheckedConnection, isExtensionConnectedResultLoading } = useSelector(
    (state: IRootState) => state.home
  );
  const dispatch = useDispatch();
  const isDocumentLoaded = useIsDocumentLoaded();

  const isLoading = !isDocumentLoaded || !hasCheckedConnection || isExtensionConnectedResultLoading;

  const actions = [
    {
      name: `View on ${getMarketplaceDisplayName(newData.marketplace)}`,
      icon: 'eva:eye-fill',
      action: () => {
        window.open(newData.orderLink, '_blank');
      },
    },
  ];

  return (
    <Paper
      data-quick-menu-id={newData.id}
      variant="outlined"
      sx={{
        my: 'auto',
        minHeight: '37px',
        display: 'none',
        alignItems: 'center',
        py: '2px',
        boxShadow: (theme) => theme.customShadows.z8,
        transition: (theme) => theme.transitions.create('opacity'),
      }}
    >
      {actions.map((action, index) => (
        <Tooltip disableInteractive key={action.name} title={action.name}>
          <IconButton
            size="small"
            onClick={action.action}
            disabled={action.name === 'Crosslist' ? isLoading : false}
            sx={{
              mx: 0.75,
              color: index === 2 ? 'error.main' : 'text.primary',
              opacity: 0.65,
              '&:hover': { opacity: 1 },
            }}
          >
            {action.name === 'Crosslist' && isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <Iconify icon={action.icon} width={24} />
            )}
          </IconButton>
        </Tooltip>
      ))}
    </Paper>
  );
}
