import { useSelector } from 'react-redux';
import { Marketplace, OrderJobType } from '@beta.limited/primelister';
import { IRootState } from 'src/store';
import { IMyShopsTableRow } from 'src/store/dashboard/slices/myShopsSlice';
import { SupportedMarketplace, SUPPORTED_MARKETPLACES } from '../pages/dashboard/MyShops/component/MyShopsBrowserTable/tableItems/BrowserTableRow';

interface UseTrackOrdersSwitchProps {
  row: IMyShopsTableRow;
  isConnected: boolean;
}

export const useTrackOrdersSwitch = ({ row, isConnected }: UseTrackOrdersSwitchProps) => {
  const shopSettings = useSelector(
    (state: IRootState) => state.myShops.shopSettings[row.platformName]
  );

  const isLoading = shopSettings?.loading;
  const isSupported = SUPPORTED_MARKETPLACES.includes(row.platformName as SupportedMarketplace);
  
  const tooltipTitle = !isSupported
    ? 'This marketplace is not supported yet (coming soon)'
    : !isConnected
    ? `To activate Auto Delist feature, please connect your ${row.name} account first`
    : '';

  const switchProps = {
    checked: shopSettings?.data?.settings[OrderJobType.TRACK_ORDERS]?.enabled ?? false,
    disabled: !isConnected || !isSupported,
  };

  return {
    isLoading,
    tooltipTitle,
    switchProps,
    shopSettings,
    isSupported,
  };
}; 