import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro';
import { useDispatch } from 'react-redux';

import {
  setPage as setInventoryPage,
  setSortModel as setInventorySortModel,
  setInventoryFilterModel,
  setRowsPerPage as setInventoryRowsPerPage,
  setLoadingEffect as setInventoryLoadingEffect,
  inventoryGetListRequest,
  setSearchValue,
} from 'src/store/dashboard/slices/inventorySlice';

import {
  setPage as setTasksPage,
  setSortModel as setTasksSortModel,
  setTasksFilterModel,
  setRowsPerPage as setTasksRowsPerPage,
  setLoadingEffect as setTasksLoadingEffect,
  setSelectedTab,
  tasksGetRequest,
} from 'src/store/dashboard/slices/tasksSlice';

import {
  setPage as setOrdersPage,
  setSortModel as setOrdersSortModel,
  setOrdersFilterModel,
  setRowsPerPage as setOrdersRowsPerPage,
  setLoadingEffect as setOrdersLoadingEffect,
  ordersGetListRequest,
} from 'src/store/dashboard/slices/ordersSlice';

import { useSearchParams } from 'react-router-dom';

import { getFilterModelShaped } from 'src/pages/dashboard/Inventory/helpers/getFilterModelShaped';
import { useEffect } from 'react';
import { getTasksFilterModelShaped } from 'src/pages/dashboard/Tasks/helpers/getTasksFilterModelShaped';

interface IDependencies {
  datagrid: 'inventory' | 'tasks' | 'orders';
  searchValue?: string;
  inventoryFilterModel?: GridFilterModel;
  tasksFilterModel?: GridFilterModel;
  inventoryPage?: number;
  tasksPage?: number;
  inventoryRowsPerPage?: number;
  tasksRowsPerPage?: number;
  inventorySortModel?: GridSortModel;
  tasksSortModel?: GridSortModel;
  selectedTab?: string;
  ordersFilterModel?: GridFilterModel;
  ordersPage?: number;
  ordersRowsPerPage?: number;
  ordersSortModel?: GridSortModel;
}

export const useQueryParamsForDatagrid = ({
  datagrid,
  searchValue,
  inventoryFilterModel,
  tasksFilterModel,
  ordersFilterModel,
  inventoryPage,
  tasksPage,
  ordersPage,
  inventoryRowsPerPage,
  tasksRowsPerPage,
  ordersRowsPerPage,
  inventorySortModel,
  ordersSortModel,
  tasksSortModel,
  selectedTab,
}: IDependencies) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const querySearch = searchParams.get('search');
  const queryFilter = searchParams.get('filters');
  const queryOrder = searchParams.get('order');
  const queryOrderBy = searchParams.get('orderBy');
  const queryRowsPerPage = searchParams.get('rowsPerPage');
  const queryPage = searchParams.get('page') ? Number(searchParams.get('page')) - 1 : 0;
  const queryTab = searchParams.get('tab');

  const checkFilterItems = (filter: GridFilterModel | undefined) => {
    if (filter?.items && Array.isArray(filter?.items)) {
      return filter.items.length && filter.items.some((item) => item.value);
    }

    return false;
  };

  const handleQueryFilterAndState = (
    filter: GridFilterModel | undefined,
    filterSetter: (filter: GridFilterModel) => any
  ) => {
    const queryFilter = searchParams.get('filters');
    if (queryFilter) {
      dispatch(filterSetter(JSON.parse(queryFilter)));
    } else if (checkFilterItems(filter)) {
      searchParams.set('filters', JSON.stringify(filter));
    }
  };

  const handleQuerySortModelAndState = (
    sortModel: GridSortModel | undefined,
    sortModelSetter: (sortModel: GridSortModel) => any
  ) => {
    if (queryOrder && queryOrderBy) {
      dispatch(sortModelSetter([{ field: queryOrderBy, sort: queryOrder as 'asc' | 'desc' }]));
    } else if (sortModel?.length) {
      const orderBy = sortModel[0].field || 'added';
      const order = sortModel[0].sort || 'desc';
      searchParams.set('order', order as string);
      searchParams.set('orderBy', orderBy);
    }
  };

  const handleQueryPageAndState = (page: number | undefined, pageSetter: (page: number) => any) => {
    if (queryPage) {
      dispatch(pageSetter(queryPage));
    } else if (page) {
      searchParams.set('page', (page + 1).toString());
    }
  };

  const handleQueryRowsPerPageAndState = (
    rowsPerPage: number | undefined,
    rowsPerPageSetter: (rowsPerPage: number) => any
  ) => {
    if (queryRowsPerPage) {
      dispatch(rowsPerPageSetter(Number(queryRowsPerPage)));
    } else if (rowsPerPage) {
      searchParams.set('rowsPerPage', rowsPerPage.toString());
    }
  };

  const handleQuerySearchValueAndState = (searchValue: string | undefined) => {
    if (querySearch) {
      dispatch(setSearchValue(decodeURIComponent(querySearch)));
    } else if (searchValue && searchValue !== '') {
      searchParams.set('search', searchValue);
    }
  };

  const handleQuerySelectedTabAndState = (selectedTab: string | undefined) => {
    if (queryTab) {
      queryTab && dispatch(setSelectedTab(queryTab));
    } else {
      if (selectedTab) {
        searchParams.set('tab', selectedTab);
      }
    }
  };

  useEffect(() => {
    //--inventory
    if (datagrid === 'inventory') {
      handleQuerySortModelAndState(inventorySortModel, setInventorySortModel);
      handleQueryPageAndState(inventoryPage, setInventoryPage);
      handleQueryRowsPerPageAndState(inventoryRowsPerPage, setInventoryRowsPerPage);
      handleQuerySearchValueAndState(searchValue);
      handleQueryFilterAndState(inventoryFilterModel, setInventoryFilterModel);
      setSearchParams(searchParams);

      const initialSearch = querySearch || searchValue;
      const initialFilter = queryFilter
        ? getFilterModelShaped(JSON.parse(queryFilter))
        : getFilterModelShaped(inventoryFilterModel!);

      let initialOrder;
      let initialOrderBy;

      if (inventorySortModel?.length) {
        initialOrder = queryOrder || inventorySortModel![0].sort;
        initialOrderBy = queryOrderBy || inventorySortModel![0].field;
      } else {
        initialOrder = queryOrder || 'desc';
        initialOrderBy = queryOrderBy || 'added';
      }

      const initialPage = queryPage || inventoryPage!;
      const initialRowsPerPage = queryRowsPerPage ? Number(queryRowsPerPage) : inventoryRowsPerPage;

      dispatch(setInventoryLoadingEffect(true));
      dispatch(
        inventoryGetListRequest({
          page: initialPage,
          rowsPerPage: initialRowsPerPage!,
          orderBy: initialOrderBy,
          order: initialOrder,
          filter: initialFilter,
          searchValue: initialSearch,
        })
      );
    } else if (datagrid === 'orders') {
      handleQuerySortModelAndState(ordersSortModel, setOrdersSortModel);
      handleQueryPageAndState(ordersPage, setOrdersPage);
      handleQueryRowsPerPageAndState(ordersRowsPerPage, setOrdersRowsPerPage);
      handleQuerySearchValueAndState(searchValue);
      handleQueryFilterAndState(ordersFilterModel, setOrdersFilterModel);
      setSearchParams(searchParams);

      const initialSearch = querySearch || searchValue;
      const initialFilter = queryFilter
        ? getFilterModelShaped(JSON.parse(queryFilter))
        : getFilterModelShaped(ordersFilterModel!);

      let initialOrder;
      let initialOrderBy;

      if (ordersSortModel?.length) {
        initialOrder = queryOrder || ordersSortModel![0].sort;
        initialOrderBy = queryOrderBy || ordersSortModel![0].field;
      } else {
        initialOrder = queryOrder || 'desc';
        initialOrderBy = queryOrderBy || 'added';
      }

      const initialPage = queryPage || ordersPage!;
      const initialRowsPerPage = queryRowsPerPage ? Number(queryRowsPerPage) : ordersRowsPerPage;

      dispatch(setOrdersLoadingEffect(true));
      dispatch(
        ordersGetListRequest({
          page: initialPage,
          rowsPerPage: initialRowsPerPage!,
          orderBy: initialOrderBy,
          order: initialOrder,
          filter: initialFilter,
          searchValue: initialSearch,
        })
      );
    }

    //-- Tasks
    else {
      handleQuerySortModelAndState(tasksSortModel, setTasksSortModel);
      handleQueryPageAndState(tasksPage, setTasksPage);
      handleQueryRowsPerPageAndState(tasksRowsPerPage, setTasksRowsPerPage);
      handleQuerySelectedTabAndState(selectedTab);
      handleQueryFilterAndState(tasksFilterModel, setTasksFilterModel);

      setSearchParams(searchParams);

      const initialFilter = queryFilter
        ? getTasksFilterModelShaped(JSON.parse(queryFilter))
        : getTasksFilterModelShaped(tasksFilterModel!);

      let initialOrder;
      let initialOrderBy;

      if (tasksSortModel?.length) {
        initialOrder = queryOrder || tasksSortModel![0].sort;
        initialOrderBy = queryOrderBy || tasksSortModel![0].field;
      } else {
        initialOrder = queryOrder || 'desc';
        initialOrderBy = queryOrderBy || 'added';
      }
      const initialPage = tasksPage;
      const initialRowsPerPage = queryRowsPerPage ? Number(queryRowsPerPage) : tasksRowsPerPage;
      const initialTab = selectedTab!;

      dispatch(setTasksLoadingEffect(true));
      dispatch(
        tasksGetRequest({
          page: initialPage!,
          rowsPerPage: initialRowsPerPage!,
          orderBy: initialOrderBy,
          order: initialOrder!,
          filter: initialFilter,
          selectedTab: initialTab,
        })
      );
    }
  }, [selectedTab]);
};
