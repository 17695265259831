import { SubscriptionType, UserAction } from '@beta.limited/primelister';
import { Marketplace } from '@beta.limited/primelister';
import { EBAY_LISTINGS_LIMIT } from 'src/config';
import { convertMarketplaceToGeneralName } from 'src/pages/dashboard/Inventory/mainComponents/ImportTable/importDataGrid/helpers';
import { ICrosslistFilter } from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';
import { IGetMyListingsPayload } from 'src/store/automations/sagas/myListingsSaga';
import { UserTagTypesEnum } from 'src/store/automations/slices/automationsSlice';

//dashboard
export const crosslistEndpoints = {
  inventory: {
    GET_INVENTORY: (
      page: number,
      rowsPerPage: number,
      orderBy: string | null | undefined,
      order: string | null | undefined,
      filter: ICrosslistFilter,
      searchValue: string | undefined
    ) => {
      const params = new URLSearchParams({
        page: `${page}`,
        rowsPerPage: `${rowsPerPage}`,
        orderBy: orderBy || '',
        order: order || '',
      });

      if (searchValue) {
        params.set('search', `${searchValue}`);
      }

      if (filter) {
        params.set('filter', `${filter}`);
      }

      return `/inventory?${params.toString()}`;
    },
    GET_INVENTORY_DELETE: () => '/inventory/delete/bulk/',
    GET_INVENTORY_ADD_TO_GROUP: () => '/inventory/items/add-group',
    GET_INVENTORY_DELETE_GROUP: () => '/inventory/items/delete-group',
    GET_INVENTORY_ADD_TO_TAG: () => '/inventory/items/add-tags',
    GET_INVENTORY_DELETE_TAGS: () => '/inventory/items/delete-tags',
    GET_INVENTORY_UPDATE_TAGS: () => '/inventory/items/update-tags',
    GET_INVENTORY_UPDATE_STATUS: () => '/inventory/items/bulk-update-status',
    GET_INVENTORY_MERGE_LISTINGS: () => '/inventory/items/merge',
    GET_INVENTORY_GET_LISTING_TO_EDIT: (id: string) => `/inventory/items/${id}`,
    GET_INITIAL_IMPORT_INFORMATION_OF_LISTINGS: () => '/listings/bulk-check-status',
    GET_API_BULK_IMPORT: () => '/listings/bulkInsert',
    GET_USER_ACTION_QUEUE_JOB: () => `/user-action-queue/bulk`,
    GET_IMPORT_TABLE_LISTINGS_ENDPOINT: ({
      marketplace,
      shop,
      shopId,
      search,
      page,
      sendWithMarketplace,
      limit,
    }: {
      marketplace: Marketplace;
      shop?: string;
      search?: string;
      page?: string;
      shopId?: string;
      sendWithMarketplace?: boolean;
      limit?: string;
    }) => {
      const marketplaceGeneralName = convertMarketplaceToGeneralName(marketplace);
      const params = new URLSearchParams({
        limit: limit || '100',
        ...(shop && { shop }),
        ...(shopId && { shopId }),
        ...(sendWithMarketplace && { marketplace }),
        ...(search && { search }),
        ...(page && { page }),
      });

      const url = `/shops/${marketplaceGeneralName}/listings?${params.toString()}`;
      return url;
    },
  },
  orders: {
    GET_ORDERS: (
      page: number,
      rowsPerPage: number,
      orderBy: string | null | undefined,
      order: string | null | undefined,
      filter: ICrosslistFilter,
      searchValue: string | undefined
    ) => {
      const params = new URLSearchParams({
        page: `${page}`,
        rowsPerPage: `${rowsPerPage}`,
        orderBy: orderBy || '',
        order: order || '',
      });

      if (searchValue) {
        params.set('search', `${searchValue}`);
      }

      if (filter) {
        params.set('filter', `${filter}`);
      }

      return `/shops/orders?${params.toString()}`;
    },
    GET_ORDER_DETAIL: (id: string) => `/shops/orders/${id}/related-items`,
  },
  tasks: {
    GET_TASKS: (
      page: number,
      rowsPerPage: number,
      orderBy: string,
      order: string,
      filter: ICrosslistFilter
    ) => {
      const params = new URLSearchParams({
        page: `${page}`,
        rowsPerPage: `${rowsPerPage}`,
        orderBy: orderBy || '',
        order: order || '',
      });

      if (filter) {
        params.set('filter', `${filter}`);
      }

      return `/tasks/list-task-records?${params.toString()}&search=completed`;
    },
    GET_TASKS_BULK_DELETE: () => '/tasks/delete-task-records',
    GET_TASK_ERRORS: () => '/error-texts',
  },
  myshops: {
    GET_ALL_SHOPS: () => '/shops',
    GET_SHOP_SETTINGS: ({ marketplace, shop }: { marketplace: string; shop: string }) => {
      const params = new URLSearchParams({
        marketplace,
        shop,
      });
      return `/shops/settings?${params.toString()}`;
    },
    UPDATE_SHOP_SETTINGS: () => '/shops/settings',
    shopify: {
      GET_SHOPIFY_API_AUTH: () => '/shops/shopify/generateShopAuthURL',
      GET_SHOPIFY_API_CREATE_CREDENTIALS: () => '/shops/shopify/credential',
      GET_SHOPIFY_API_PRODUCTS: () => '/shops/shopify/listings',
      GET_SHOPIFY_API_PRODUCT_DETAILS: (id: string) => `/shops/shopify/listings/${id}`,
    },
    ebay: {
      GET_EBAY_API_AUTH: ({ automation = false }: { automation?: boolean } = {}) =>
        `/shops/ebay/generateShopAuthURL?automation=${automation}`,
      GET_EBAY_API_CREATE_CREDENTIALS: () => '/shops/ebay/credential',
      GET_EBAY_API_PRODUCTS: (pageID: any, search: string) => {
        let url = `/shops/ebay/us/listings?page=${pageID}&limit=${EBAY_LISTINGS_LIMIT}`;

        if (search) {
          url = `${url}&search=${search}`;
        }

        return url;
      },
      GET_EBAY_API_PRODUCT_DETAILS: (id: string) => `/shops/ebay/listing/${id}`,
    },
  },
  account: {
    GET_ACCOUNT: () => '/account',
    GET_AFFILIATE: () => '/account/affiliate',
    GET_NOTIFICATIONS: () => '/account/notifications',
    GET_ANNOUNCEMENTS: () => '/account/announcements',
    CHECK_PERMISSION: () => '/account/check-permission',
  },
  affiliate: {
    UPDATE_PAYOUT_REQUEST_DATE: () => '/affiliate/updatePayoutRequestDate',
    GET_AFFILIATE_SUMMARY: () => '/affiliate/summary',
    GET_AFFILIATE_BALANCE_HISTORY: ({
      page,
      size,
      order,
      orderBy,
    }: {
      page: number;
      size: number;
      order: string;
      orderBy: string;
    }) => {
      const params = new URLSearchParams({
        page: `${page}`,
        size: `${size}`,
        order: order || '',
        orderBy: orderBy || '',
      });

      return `/affiliate/balance-history?${params.toString()}`;
    },
  },
  billing: {
    GET_BILLING_SUBSCRIPTIONS: () => '/billing/subscriptions',
    GET_BILLING_INVOICES: () => 'billing/invoices',
    GET_CREATE_PRICING_SESSION: () => '/billing/customer-portal-sessions',
    GET_CARDS_INFO: () => 'billing/cards',
    GET_STRIPE_COUPON_CHECK: (planName: string, coupon: string) =>
      `billing/coupons/${planName}/${coupon}`,
    GET_BILLING_CHECKOUT: () => 'billing/checkout',
  },
  groupsAndTags: {
    GET_TAGS: () => '/groups-and-tags/tags',
    GET_GROUPS: () => '/groups-and-tags/groups',
  },
  auth: {
    GET_PASSWORD: () => '/account/change-password',
    GET_RESET_PASSWORD: () => '/account/forgot-password',
    GET_NEW_PASSWORD: () => '/account/reset-password',
    GET_AUTH_LOGIN: () => '/auth/login',
    GET_APP_VERSIONS: () => `/devices/app-versions`,
    GET_SIGN_UP: () => '/auth/signup',
    GET_REFRESH_ACCESS_TOKEN: (token: string) => `/auth/refresh-token?token=${token}`,
    GET_SEND_VERIFICATION: (email: string) => `/account/resend?email=${email}`,
  },
};
//automation
export const poshmarkAutomationEndpoints = {
  automation: {
    GET_AUTOMATIONS: ({
      marketplace,
      activeClosetCredentialsId,
    }: {
      marketplace: string;
      activeClosetCredentialsId: string;
    }) => `/automations/settings/${marketplace}/${activeClosetCredentialsId}`,
    GET_SHIPPING_OFFERS: (marketplace: string) =>
      `/automations/settings/${marketplace}/shipping-offers`,
    UPDATE_AUTOMATIONS: () => '/automations/settings',
    CREATE_AUTOMATION_WORKER: (status: 'activate' | 'deactivate') => `/automations/${status}`,
    GET_USER_TAGS: (id: string) => `/account/${id}/tags`,
    SET_USER_TAG: (id: string, userTagType: UserTagTypesEnum) =>
      `/account/${id}/tags/${userTagType}`,
  },
  closet: {
    GET_CLOSETS: (marketplace: string = 'poshmark') => `/automations/account/${marketplace}/shops`,
    CONNECT_CLOSETS: (marketplace: string = 'poshmark') => `/automations/account/${marketplace}`,
    REMOVE_CLOSET: (id: string, marketplace: string = 'poshmark') =>
      `/automations/account/${marketplace}/${id}`,
  },
  activity: {
    GET_ACTIVITY: (activeClosetAutomationPlatform: string) =>
      `/automations/${activeClosetAutomationPlatform}`,
    GET_ACTIVE_JOBS: ({
      shop,
      product,
      action,
    }: {
      shop: string;
      product: SubscriptionType;
      action?: UserAction;
    }) => {
      const params = new URLSearchParams({
        shop,
        product,
        ...(action && { action }),
      });

      const url = `/user-action-queue/active-jobs?${params.toString()}`;
      return url;
    },
    REMOVE_ALL_ACTIVE_JOBS: (shop: string) => {
      const url = `/user-action-queue/remove-jobs?shop=${shop}&product=${SubscriptionType.POSHMARK_AUTOMATION}`;
      return url;
    },
    REMOVE_SINGLE_ACTIVE_JOB: ({ jobId }: { jobId: string }) => {
      const url = `/user-action-queue/${jobId}`;
      return url;
    },
  },

  statistics: {
    GET_CLOSET_STATISTICS: () => '/tasks/total-task-counts',
  },
  ebay: {
    addEbayShop: () => '/automations/account/ebay',
  },
  listings: {
    GET_MY_LISTINGS: (payload: IGetMyListingsPayload) => {
      const params = new URLSearchParams();

      // Destructure filters and credentialsId for easier access
      const { filters, credentialsId } = payload;

      // Append parameters if they exist
      Object.entries(filters || {}).forEach(([key, value]) => {
        params.append(key, value.toString());
      });

      // Build the URL using template literals
      const baseUrl = `/shops/poshmark/${credentialsId}/listings`;
      const queryString = params.toString();
      const url = queryString ? `${baseUrl}?${queryString}` : baseUrl;

      return url;
    },
    GET_FILTER_CATEGORIES: (credentialsId: string, department?: string, category?: string) => {
      const params = new URLSearchParams();
      if (department) params.append('department', department);
      if (category) params.append('category', category);
      const url = `/shops/poshmark/${credentialsId}/categories?${params.toString()}`;
      return url;
    },
  },
};

export const extensionActionsEndpoints = {
  UPDATE_INSTALLATION_STATUS: () => `/account/extension-installation-status`,
};
