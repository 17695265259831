import { useDispatch, useSelector } from 'react-redux';
import {
  orderDetailRequest,
  delistOrderRequest,
  setIsManageSubscriptionsDialogOpen,
  setManageSubscriptionDialogData,
} from 'src/store/dashboard/slices/ordersSlice';
import { useEffect } from 'react';
import { Box, Button, CircularProgress, Stack, Tooltip, Typography } from '@mui/material';
import { IRootState } from 'src/store';
import Label from 'src/components/label';
import { getMarketplaceDisplayName, Marketplace } from '@beta.limited/primelister';
import { IOrderItemMarketplace, IOrderTaskRecord } from '../../../types/dataGridInterfaces';
import { LoadingButton } from '@mui/lab';
import useCheckPermission, { PermissionCheckActionEnum } from 'src/hooks/useCheckPermission';
import { alpha } from '@mui/material/styles';
import { getMarketplaceColor } from 'src/pages/dashboard/Tasks/helpers/getMarketplaceColor';
import { getShopperId } from 'src/pages/dashboard/Inventory/mainComponents/ImportTable/importDataGrid/helpers';

// Status type definition
type DelistStatus = 'Success' | 'Failed' | 'Not Supported';
type StatusColor = 'success' | 'error' | 'info';

interface StatusConfig {
  color: StatusColor;
  tooltip: string | JSX.Element;
}

const getStatusConfig = (
  status: DelistStatus,
  taskRecord: IOrderTaskRecord | null
): StatusConfig => {
  const configs: Record<DelistStatus, StatusConfig> = {
    Success: {
      color: 'success',
      tooltip: 'We successfully auto-delisted your listing from this marketplace.',
    },
    Failed: {
      color: 'error',
      tooltip: (
        <>
          We tried to auto-delist on the marketplace but couldn't get it to work. You'll need to
          delist manually, or you can try the delist button again.
          {taskRecord?.errorText && <Box sx={{ mt: 1 }}>Error Message: {taskRecord.errorText}</Box>}
        </>
      ),
    },
    'Not Supported': {
      color: 'info',
      tooltip:
        "We don't support an auto-delist feature on this marketplace, so you'll need to delist manually.",
    },
  };

  return configs[status];
};

const OrderDetailPanel = ({ row }: { row: any }) => {
  const dispatch = useDispatch();
  const orderDetail = useSelector(
    (state: IRootState) =>
      state.orders.orderDetails[row.id] ?? {
        loading: false,
        error: null,
        data: [],
      }
  );

  // Get loading state from redux
  const isLoading = useSelector((state: IRootState) => state.orders.loading);
  const myShops = useSelector((state: IRootState) => state.myShops);

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    if (!orderDetail.data?.length && !orderDetail.loading) {
      dispatch(orderDetailRequest({ id: row.id }));
    }
  }, [row.id]);

  if (orderDetail?.loading) {
    return (
      <Box sx={{ p: 2, pl: 16, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (orderDetail?.error) {
    return (
      <Box sx={{ p: 2, pl: 16 }}>
        <Typography color="error">Error loading details: {orderDetail.error}</Typography>
      </Box>
    );
  }

  if (!orderDetail?.data?.length) {
    return (
      <Box sx={{ p: 1, pl: 16, display: 'flex', justifyContent: 'center' }}>
        <Typography color="text.secondary" fontSize={14}>
          There is nothing in your inventory related to this order.
        </Typography>
      </Box>
    );
  }

  const getDelistStatus = (marketplaceItem: IOrderItemMarketplace): DelistStatus => {
    if (marketplaceItem.marketplace === Marketplace.FACEBOOK) return 'Not Supported';
    if (!marketplaceItem.taskRecord) return 'Failed';
    return marketplaceItem.taskRecord.status === 'Succeeded' ? 'Success' : 'Failed';
  };

  const handleDelist = async (marketplaceItem: IOrderItemMarketplace) => {
    try {
      await checkPermission(marketplaceItem.marketplace, PermissionCheckActionEnum.DELIST);

      const shopId = getShopperId(marketplaceItem.marketplace as Marketplace, myShops);

      dispatch(
        delistOrderRequest({
          marketplace: marketplaceItem.marketplace,
          shop: shopId,
          listings: [
            {
              id: marketplaceItem.id,
              image: orderDetail.data[0].thumbnail ?? '',
              title: orderDetail.data[0].title ?? '',
            },
          ],
        })
      );
    } catch (error) {
      dispatch(setManageSubscriptionDialogData(error));
      dispatch(setIsManageSubscriptionsDialogOpen(true));
    }
  };

  return (
    <Box sx={{ p: 2, pl: 16 }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '2fr 2fr 1fr',
          gap: 1,
          mb: 1,
          px: 1,
        }}
      >
        <Typography variant="subtitle2" color="text.secondary">
          Marketplace
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          Status
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          Actions
        </Typography>
      </Box>

      {orderDetail.data.flatMap((item) =>
        item.items.map((marketplaceItem: IOrderItemMarketplace) => {
          const status = getDelistStatus(marketplaceItem);
          const statusConfig = getStatusConfig(status, marketplaceItem.taskRecord);

          return (
            <Box
              key={marketplaceItem.id}
              sx={{
                display: 'grid',
                gridTemplateColumns: '2fr 2fr 1fr',
                gap: 1,
                alignItems: 'center',
                py: 0.75,
                px: 1,
                '&:hover': {
                  bgcolor: 'rgba(145, 158, 171, 0.08)',
                },
              }}
            >
              <Stack spacing={0.5} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Label
                  variant="soft"
                  sx={{
                    color: getMarketplaceColor(marketplaceItem.marketplace.toLowerCase()),
                    backgroundColor: alpha(
                      getMarketplaceColor(marketplaceItem.marketplace.toLowerCase()),
                      0.16
                    ),
                    textTransform: 'none',
                    letterSpacing: '0.04rem',
                    width: 'fit-content',
                  }}
                >
                  {getMarketplaceDisplayName(marketplaceItem.marketplace)}
                </Label>
              </Stack>

              <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                <Tooltip title={statusConfig.tooltip} arrow>
                  <Label color={statusConfig.color}>
                    {marketplaceItem.taskRecord?.status || status}
                  </Label>
                </Tooltip>
              </Box>

              <Stack
                direction="row"
                spacing={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <LoadingButton
                  size="small"
                  variant="contained"
                  color="primary"
                  loading={isLoading}
                  disabled={status === 'Not Supported'}
                  onClick={() => handleDelist(marketplaceItem)}
                >
                  Delist
                </LoadingButton>
                <Button
                  size="small"
                  variant="soft"
                  color="inherit"
                  onClick={() =>
                    marketplaceItem.marketplaceLink &&
                    window.open(marketplaceItem.marketplaceLink, '_blank')
                  }
                >
                  View Item
                </Button>
              </Stack>
            </Box>
          );
        })
      )}
    </Box>
  );
};

export default OrderDetailPanel;
