// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';
import Iconify from 'src/components/iconify';
import { Box } from '@mui/material';
import { memoize } from 'lodash';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);
const renderMyShopsIcon = () => (
  <Box
    sx={{
      p: 0.1,
      backgroundColor: '#E4E8EB',
      borderRadius: 0.5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Iconify icon="ep:menu" />
  </Box>
);

const ordersIcon = () => (
  <Box
    sx={{
      p: 0.1,
      backgroundColor: '#E4E8EB',
      borderRadius: 0.5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Iconify icon="solar:clipboard-list-outline" />
  </Box>
);

const ICONS = {
  dashboard: icon('ic_dashboard'),
  inventory: icon('ic_ecommerce'),
  tasks: icon('ic_file'),
  groupsAndTags: icon('ic_label'),
  account: icon('ic_user'),
};

const createNavConfig = memoize((isWebMobile: boolean, isPrimeListerMobileApp: boolean) => [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Navigation',
    items: [
      {
        title: 'Home',
        path: PATH_DASHBOARD.root,
        icon: ICONS.dashboard,
        tooltip: 'Learn how to use',
      },
      {
        title: 'Inventory',
        path: PATH_DASHBOARD.inventory.root,
        icon: ICONS.inventory,
        tooltip: 'Manage products',
      },
      {
        title: 'Tasks',
        path: PATH_DASHBOARD.tasks,
        icon: ICONS.tasks,
        tooltip: 'Tasks timeline',
      },
      {
        title: 'Orders',
        path: PATH_DASHBOARD.orders,
        icon: ordersIcon(),
        tooltip: 'Orders',
        isNew: true,
      },
      ...(!isWebMobile
        ? [
            {
              title: 'My Shops',
              path: PATH_DASHBOARD.myShops,
              icon: renderMyShopsIcon(),
              tooltip: 'My Shops',
            },
            {
              title: 'Account',
              path: PATH_DASHBOARD.user.root,
              icon: ICONS.account,
              tooltip: 'Account & Subscription',
            },
          ]
        : []),
    ],
  },
]);

export default createNavConfig;
