import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Tooltip, ListItemText, Link, Chip } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
// auth

//
import Iconify from '../../iconify';
import { NavItemProps } from '../types';
import { StyledItem, StyledIcon } from './styles';

// ----------------------------------------------------------------------

const NavItem = forwardRef<HTMLDivElement, NavItemProps>(
  ({ item, depth, open, active, isExternalLink, ...other }, ref) => {
    const { translate } = useLocales();

    const { title, path, icon, info, children, disabled, caption, roles } = item;

    const subItem = depth !== 1;

    const renderContent = (
      <StyledItem
        ref={ref}
        open={open}
        depth={depth}
        active={active}
        disabled={disabled}
        {...other}
      >
        {icon && <StyledIcon>{icon}</StyledIcon>}

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ListItemText
            primary={translate(title)}
            primaryTypographyProps={{
              noWrap: true,
              component: 'span',
              variant: active ? 'subtitle2' : 'body2',
            }}
          />
          
          {item.isNew && (
            <Chip
              label="New"
              size="small"
              color="primary"
              sx={{ 
                ml: 1,
                height: 18,
                fontSize: '0.6rem',
                fontWeight: 'bold',
                '& .MuiChip-label': {
                  px: 0.75,
                }
              }}
            />
          )}
        </Box>

        {info && (
          <Box component="span" sx={{ ml: 1, lineHeight: 0 }}>
            {info}
          </Box>
        )}

        {caption && (
          <Tooltip title={translate(caption)} arrow>
            <Box component="span" sx={{ ml: 0.5, lineHeight: 0 }}>
              <Iconify icon="eva:info-outline" width={16} />
            </Box>
          </Tooltip>
        )}

        {!!children && (
          <Iconify
            icon={subItem ? 'eva:chevron-right-fill' : 'eva:chevron-down-fill'}
            width={16}
            sx={{ ml: 0.5, flexShrink: 0 }}
          />
        )}
      </StyledItem>
    );

    const renderItem = () => {
      // ExternalLink
      if (isExternalLink)
        return (
          <Tooltip title={item.tooltip} placement={'top'} arrow>
            <Link href={path} target="_blank" rel="noopener noreferrer" underline="none">
              {renderContent}
            </Link>
          </Tooltip>
        );

      // Default
      return (
        <Tooltip title={item.tooltip} placement={'top'} arrow>
          <Link to={path} component={RouterLink} underline="none">
            {renderContent}
          </Link>
        </Tooltip>
      );
    };

    return renderItem();
  }
);

export default NavItem;
