import { all, fork } from 'redux-saga/effects';
import { accountModuleSaga } from './dashboard/sagas/accountSaga';
import { authModuleSaga } from './dashboard/sagas/authSaga';
import { tasksModuleSaga } from './dashboard/sagas/tasksSaga';
import { inventoryModuleSaga } from './dashboard/sagas/inventorySaga';
import { importTableModuleSaga } from './dashboard/sagas/importTableSaga';
import { groupsAndTagsModuleSaga } from './dashboard/sagas/groupsAndTagsSaga';
import { pricingModuleSaga } from './dashboard/sagas/pricingSaga';
import { automationsModuleSaga } from './automations/sagas/automationsSaga';
import { activityModuleSaga } from './automations/sagas/activitySaga';
import { myClosetModuleSaga } from './automations/sagas/myClosetSaga';
import { statisticsModuleSaga } from './automations/sagas/statisticsSaga';
import { myShopsAPITableModuleSaga } from './dashboard/sagas/myShopsAPITableSaga';
import { myListingsModuleSaga } from './automations/sagas/myListingsSaga';
import { myShopsModuleSaga as ebayMyShopsModuleSaga } from './automations/ebay/sagas/myShopsSaga.ebay';
import { automationsModuleSaga as ebayAutomationsModuleSaga } from './automations/ebay/sagas/automationsSaga.ebay';
import { activityModuleSaga as ebayActivityModuleSaga } from './automations/ebay/sagas/activitySaga.ebay';
import { ordersModuleSaga } from './dashboard/sagas/ordersSaga';

function* rootSaga() {
  //--Dashboard
  yield all([fork(accountModuleSaga)]);
  yield all([fork(authModuleSaga)]);
  yield all([fork(tasksModuleSaga)]);
  yield all([fork(inventoryModuleSaga)]);
  yield all([fork(importTableModuleSaga)]);
  yield all([fork(groupsAndTagsModuleSaga)]);
  yield all([fork(pricingModuleSaga)]);
  yield all([fork(myShopsAPITableModuleSaga)]);
  yield all([fork(ordersModuleSaga)]);
  //--Automations
  yield all([fork(automationsModuleSaga)]);
  yield all([fork(activityModuleSaga)]);
  yield all([fork(myClosetModuleSaga)]);
  yield all([fork(statisticsModuleSaga)]);
  yield all([fork(myListingsModuleSaga)]);
  //--eBay
  yield all([fork(ebayActivityModuleSaga)]);
  yield all([fork(ebayAutomationsModuleSaga)]);
  yield all([fork(ebayMyShopsModuleSaga)]);
}

export default rootSaga;
