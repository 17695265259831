import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';

import tasksReducer, { ITasksState } from './dashboard/slices/tasksSlice';
import homeReducer, { IHomeState } from './dashboard/slices/homeSlice';
import authReducer, { IAuthState } from './dashboard/slices/authSlice';
import groupsAndTagsReducer, { IGroupsAndTagsState } from './dashboard/slices/groupsAndTagsSlice';
import accountReducer, { IAccountState } from './dashboard/slices/accountSlice';
import inventoryReducer, { IInventoryState } from './dashboard/slices/inventorySlice';
import importTableReducer, { IImportTableState } from './dashboard/slices/importTableSlice';
import myShopsReducer, { IMyShopsState } from './dashboard/slices/myShopsSlice';
import pricingReducer, { IPricingState } from './dashboard/slices/pricingSlice';
import automationsReducer, { IAutomationsState } from './automations/slices/automationsSlice';
import activityReducer, { IActivityState } from './automations/slices/activitySlice';
import myClosetReducer, { IMyClosetState } from './automations/slices/myClosetSlice';
import statisticsReducer, { IStatisticsState } from './automations/slices/statisticsSlice';
import myShopsAPITableReducer, { IMyShopsAPITable } from './dashboard/slices/myShopsAPITableSlice';
import myListingsReducer, { IMyListingsState } from './automations/slices/myListingsSlice';
import ordersReducer, { IOrdersState } from './dashboard/slices/ordersSlice';
// Import eBay reducers
import ebayAutomationsReducer, {
  IEbayAutomationsState,
} from './automations/ebay/slices/automationsSlice.ebay';
import ebayActivityReducer, {
  IEbayActivityState,
} from './automations/ebay/slices/activitySlice.ebay';
import ebayMyShopsReducer, { IEbayMyShopsState } from './automations/ebay/slices/myShopsSlice.ebay';
import ebayStatisticsReducer, {
  IEbayStatisticsState,
} from './automations/ebay/slices/statisticsSlice.ebay';

import rootSaga from './rootSaga';

export interface IRootState {
  home: IHomeState;
  tasks: ITasksState;
  groupsAndTags: IGroupsAndTagsState;
  account: IAccountState;
  auth: IAuthState;
  inventory: IInventoryState;
  pricing: IPricingState;
  automations: IAutomationsState;
  myShops: IMyShopsState;
  activity: IActivityState;
  myCloset: IMyClosetState;
  importTable: IImportTableState;
  statistics: IStatisticsState;
  myShopsAPITable: IMyShopsAPITable;
  myListings: IMyListingsState;
  orders: IOrdersState;
  // Add eBay states
  ebayAutomations: IEbayAutomationsState;
  ebayActivity: IEbayActivityState;
  ebayMyShops: IEbayMyShopsState;
  ebayStatistics: IEbayStatisticsState;
}

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    home: homeReducer,
    tasks: tasksReducer,
    groupsAndTags: groupsAndTagsReducer,
    account: accountReducer,
    auth: authReducer,
    inventory: inventoryReducer,
    myShops: myShopsReducer,
    pricing: pricingReducer,
    automations: automationsReducer,
    activity: activityReducer,
    myCloset: myClosetReducer,
    importTable: importTableReducer,
    statistics: statisticsReducer,
    myShopsAPITable: myShopsAPITableReducer,
    myListings: myListingsReducer,
    orders: ordersReducer,
    // Add eBay reducers
    ebayAutomations: ebayAutomationsReducer,
    ebayActivity: ebayActivityReducer,
    ebayMyShops: ebayMyShopsReducer,
    ebayStatistics: ebayStatisticsReducer,
  },
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);
export default store;
