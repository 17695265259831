import { Box, MenuItem } from '@mui/material';

import OrdersDataGrid from './dataGrid/OrdersDataGrid';

export default function OrdersTable() {
  return (
    <Box sx={{ width: '100%' }}>
      <OrdersDataGrid />
    </Box>
  );
}
