import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, select, take, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { crosslistEndpoints } from 'src/endpoints';
import { shapeListingsForImportCheck } from 'src/pages/dashboard/Inventory/helpers/helpersForImportStatusCheck';

import axiosInstance from 'src/utils/axios';
import {
  importGetInitialStatusRequest,
  resetImportDialogState,
  setHasMoreImportableListings,
  setImportLoading,
  setImportNextPageID,
  setListingsForImport,
} from '../slices/importTableSlice';

import {
  //--All shops
  getAllConnectedApiShopsFail,
  getAllConnectedApiShopsRequest,
  getAllConnectedApiShopsSuccess,
  //--Shopify
  shopifyAuthFail,
  shopifyAuthRequest,
  shopifyAuthSuccess,
  shopifyCreateCredentialSuccess,
  shopifyCreateCredentialFail,
  shopifyCreateCredentialRequest,
  getShopifyProductsSuccess,
  getShopifyProductsFail,
  getShopifyProductsRequest,
  getShopifyProductDetailSuccess,
  getShopifyProductDetailFail,
  getShopifyProductDetailRequest,

  //--Ebay
  ebayAuthFail,
  ebayAuthRequest,
  ebayAuthSuccess,
  ebayCreateCredentialRequest,
  ebayCreateCredentialSuccess,
  ebayCreateCredentialFail,
  getEbayProductsRequest,
  getEbayProductDetailRequest,
  getEbayProductsSuccess,
  getEbayProductsFail,
  getEbayProductDetailSuccess,
  getEbayProductDetailFail,
  IAPIMarketplaces,
} from '../slices/myShopsAPITableSlice';
import { ConnectionStatus } from 'src/store/automations/slices/myClosetSlice';
import { setOpenAPIConnectionRequiredDialog } from '../slices/inventorySlice';
import { Marketplace, ShopSettings } from '@beta.limited/primelister';
import {
  getShopSettingsRequest,
  getShopSettingsSuccess,
  getShopSettingsFail,
  updateShopSettingsRequest,
  updateShopSettingsSuccess,
  updateShopSettingsFail,
  setIsPlatformLoggedIn,
  IPlatformStateFinder,
  IPlatforms,
  setPlatformData,
} from '../slices/myShopsSlice';
import { IRootState } from 'src/store';
import { getShopperId } from 'src/pages/dashboard/Inventory/mainComponents/ImportTable/importDataGrid/helpers';
import { takeWhile } from 'lodash';
import {
  SupportedMarketplace,
  SUPPORTED_MARKETPLACES,
} from 'src/pages/dashboard/MyShops/component/MyShopsBrowserTable/tableItems/BrowserTableRow';
import { toast } from 'react-toastify';

//--SHOPIFY
function* shopifyAuthSaga(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(crosslistEndpoints.myshops.shopify.GET_SHOPIFY_API_AUTH(), {
        params: {
          shop: action.payload,
        },
      })
    );
    window.open(response.data.data, '_self');
    yield put(shopifyAuthSuccess());
  } catch (error) {
    yield put(shopifyAuthFail(error.message));
  }
}

function* ebayAuthSaga(action: PayloadAction<{ country: string }>) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(crosslistEndpoints.myshops.ebay.GET_EBAY_API_AUTH(), {
        params: {
          country: action.payload,
        },
      })
    );

    window.open(response.data.data, '_self');
    yield put(ebayAuthSuccess());
  } catch (error) {
    yield put(ebayAuthFail(error.message));
  }
}

function* ebayCreateCredentialsSaga(action: PayloadAction<{ code: string }>) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.post(
        crosslistEndpoints.myshops.ebay.GET_EBAY_API_CREATE_CREDENTIALS(),
        action.payload
      )
    );

    yield put(ebayCreateCredentialSuccess(response.data.data));
    if (response.data.data) {
      const { connectionStatus } = response.data.data;

      if (connectionStatus === ConnectionStatus.Connected) {
        yield put(getAllConnectedApiShopsRequest());
      }
    }
  } catch (error) {
    yield put(ebayCreateCredentialFail(error.message));
  }
}

function* shopifyCreateCredentialsSaga(action: PayloadAction<{ shop: string; code: string }>) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.post(
        crosslistEndpoints.myshops.shopify.GET_SHOPIFY_API_CREATE_CREDENTIALS(),
        action.payload
      )
    );
    yield put(shopifyCreateCredentialSuccess(response.data.data));
  } catch (error) {
    yield put(shopifyCreateCredentialFail(error.message));
  }
}

function* getShopifyProductsSaga(action: PayloadAction<{ limit: number; nextPage?: string }>) {
  try {
    yield put(setImportLoading(true));
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(crosslistEndpoints.myshops.shopify.GET_SHOPIFY_API_PRODUCTS(), {
        params: {
          ...action.payload,
        },
      })
    );
    const listingsToBeCheckedForImport = shapeListingsForImportCheck(
      response.data.listings,
      Marketplace.SHOPIFY
    );

    yield put(setListingsForImport(response.data.listings));
    yield put(importGetInitialStatusRequest(listingsToBeCheckedForImport));
    yield put(getShopifyProductsSuccess(response.data));

    if (response.data.nextPage) {
      yield put(setImportNextPageID(response.data.nextPage));
      yield put(setHasMoreImportableListings(true));
    } else {
      yield put(setHasMoreImportableListings(false));
    }
    yield put(setImportLoading(false));
  } catch (error) {
    yield put(setImportLoading(false));
    yield put(getShopifyProductsFail(error.message));
  }
}

function* getShopifyProductDetailSaga(action: PayloadAction<{ id: string }>) {
  try {
    yield call(() =>
      axiosInstance.get(
        crosslistEndpoints.myshops.shopify.GET_SHOPIFY_API_PRODUCT_DETAILS(action.payload.id)
      )
    );
    yield put(getShopifyProductDetailSuccess());
  } catch (error) {
    yield put(getShopifyProductDetailFail(error.message));
  }
}

function* getEbayProductsSaga(
  action: PayloadAction<{
    limit: number;
    nextPage?: string;
    platformName: string;
    importSearchText: string;
  }>
) {
  try {
    yield put(setImportLoading(true));

    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(
        crosslistEndpoints.myshops.ebay.GET_EBAY_API_PRODUCTS(
          action.payload.nextPage ?? 1,
          encodeURIComponent(action.payload.importSearchText) ?? ''
        )
      )
    );

    const listingsToBeCheckedForImport = shapeListingsForImportCheck(
      response.data.listings,
      action.payload.platformName
    );

    yield put(setListingsForImport(response.data.listings));
    yield put(importGetInitialStatusRequest(listingsToBeCheckedForImport));
    yield put(
      getEbayProductsSuccess({
        ...response.data,
        platformName: action.payload.platformName as unknown as IAPIMarketplaces,
      })
    );

    if (response.data.nextPage) {
      yield put(setImportNextPageID(response.data.nextPage));
      yield put(setHasMoreImportableListings(true));
    } else {
      yield put(setHasMoreImportableListings(false));
    }
    yield put(setImportLoading(false));
  } catch (error) {
    yield put(setImportLoading(false));
    if (error.message.toLowerCase().includes('auth tokens')) {
      yield put(resetImportDialogState());
      yield put(setOpenAPIConnectionRequiredDialog(true));
    }
    yield put(
      getEbayProductsFail({
        message: error.message,
        platformName: action.payload.platformName as unknown as IAPIMarketplaces,
      })
    );
  }
}

function* getEbayProductDetailSaga(action: PayloadAction<{ id: string }>) {
  try {
    yield call(() =>
      axiosInstance.get(
        crosslistEndpoints.myshops.ebay.GET_EBAY_API_PRODUCT_DETAILS(action.payload.id)
      )
    );
    yield put(getEbayProductDetailSuccess());
  } catch (error) {
    yield put(getEbayProductDetailFail(error.message));
  }
}
//--FETCH ALL SHOPS INFO OF THE USER
function* getAllConnectedApiShopsSaga(action: PayloadAction<{ id: string }>) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(crosslistEndpoints.myshops.GET_ALL_SHOPS())
    );

    yield put(getAllConnectedApiShopsSuccess(response.data.shops));
  } catch (error) {
    yield put(getAllConnectedApiShopsFail(error.message));
  }
}

function* getShopSettingsSaga(action: PayloadAction<{ marketplace: Marketplace; shop: string }>) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(
        crosslistEndpoints.myshops.GET_SHOP_SETTINGS({
          marketplace: action.payload.marketplace,
          shop: action.payload.shop,
        })
      )
    );

    yield put(
      getShopSettingsSuccess({
        marketplace: action.payload.marketplace,
        shop: action.payload.shop,
        data: response.data.data,
      })
    );
  } catch (error) {
    yield put(
      getShopSettingsFail({
        marketplace: action.payload.marketplace,
        shop: action.payload.shop,
        error: error.message,
      })
    );
  }
}

function* updateShopSettingsSaga(
  action: PayloadAction<{
    marketplace: Marketplace;
    shop: string;
    settings: ShopSettings;
  }>
) {
  try {
    yield call(() =>
      axiosInstance.patch(crosslistEndpoints.myshops.UPDATE_SHOP_SETTINGS(), action.payload)
    );

    yield put(
      updateShopSettingsSuccess({
        marketplace: action.payload.marketplace,
        shop: action.payload.shop,
      })
    );

    // Show success toast
    toast.success('Settings updated successfully', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

    // Refetch settings after update
    yield put(
      getShopSettingsRequest({
        marketplace: action.payload.marketplace,
        shop: action.payload.shop,
      })
    );
  } catch (error) {
    yield put(
      updateShopSettingsFail({
        marketplace: action.payload.marketplace,
        shop: action.payload.shop,
        error: error.message,
      })
    );

    // Show error toast
    toast.error('Failed to update settings. Please try again.', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
}

function* handlePlatformDataSaga(
  action: PayloadAction<{
    username: string;
    userId: string | number;
    platform: IPlatformStateFinder;
  }>
): Generator<any, void, any> {
  const { platform } = action.payload;
  const myShops = yield select((state: IRootState) => state.myShops);
  const shopperId = getShopperId(platform as unknown as Marketplace, myShops);

  if (shopperId && SUPPORTED_MARKETPLACES.includes(platform as unknown as SupportedMarketplace)) {
    yield put(
      getShopSettingsRequest({
        marketplace: platform as unknown as Marketplace,
        shop: shopperId,
      })
    );
  }
}

export function* myShopsAPITableModuleSaga() {
  yield takeLatest(getAllConnectedApiShopsRequest.type, getAllConnectedApiShopsSaga);
  yield takeLatest(shopifyAuthRequest.type, shopifyAuthSaga);
  yield takeLatest(shopifyCreateCredentialRequest.type, shopifyCreateCredentialsSaga);
  yield takeLatest(getShopifyProductsRequest.type, getShopifyProductsSaga);
  yield takeLatest(getShopifyProductDetailRequest.type, getShopifyProductDetailSaga);
  yield takeLatest(ebayAuthRequest.type, ebayAuthSaga);
  yield takeLatest(ebayCreateCredentialRequest.type, ebayCreateCredentialsSaga);
  yield takeLatest(getEbayProductsRequest.type, getEbayProductsSaga);
  yield takeLatest(getEbayProductDetailRequest.type, getEbayProductDetailSaga);
  yield takeEvery(getShopSettingsRequest.type, getShopSettingsSaga);
  yield takeLatest(updateShopSettingsRequest.type, updateShopSettingsSaga);
  yield takeLatest(setPlatformData.type, handlePlatformDataSaga);
}
