import { useCallback, useEffect } from 'react';
import {
  DataGridPro,
  GridSortModel,
  GridSelectionModel,
  GridFilterModel,
  GridSlotsComponentsProps,
  GridRowParams,
  GridRenderCellParams,
  GridRowId,
} from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';

import { IRootState } from 'src/store';

import { gridColumns } from './Colums';
import TaskFooter from '../others/OrdersFooter';
import OrdersToolbar from '../others/OrdersToolbar';
import { getFilterModelShaped } from 'src/pages/dashboard/Inventory/helpers/getFilterModelShaped';
import { getGroupsRequest, getTagsRequest } from 'src/store/dashboard/slices/groupsAndTagsSlice';
import EmptyContent from 'src/components/empty-content/EmptyContent';
import { Box, Zoom, Typography, CircularProgress } from '@mui/material';
import { useListingDataWithSelection } from 'src/hooks/useListingDataWithSelection';
import { useSearchParams } from 'react-router-dom';
import {
  dataGridFilterControlsForRequest,
  queryFilterItemControl,
} from 'src/utils/datagridFilterControlsForRequest';
import { useQueryParamsForDatagrid } from 'src/hooks/useQueryParamsForDatagrid';
import usePlatformLoginStatusCheck from 'src/hooks/usePlatformLoginStatusCheck';
import { checkListingSourcesLoginStatus } from 'src/utils/checkListingSourcesLoginStatus';
import Iconify from 'src/components/iconify';
import {
  ordersGetListRequest,
  setOrdersFilterModel,
  setPage,
  setLoadingEffect,
  setSortModel,
  orderDetailRequest,
} from 'src/store/dashboard/slices/ordersSlice';
import OrderDetailPanel from './OrderDetailPanel';

export default function OrdersDataGrid() {
  const dispatch = useDispatch();
  const {
    dense,
    orders,
    ordersCount,
    page,
    loadingEffect,
    sortModel,
    searchValue,
    rowsPerPage,
    ordersFilterModel,
    isOrderPageInFullscreenMode,
  } = useSelector((state: IRootState) => state.orders);
  const { getNewlyAddedListings, removeDeselectedListings } = useListingDataWithSelection();
  const [searchParams, setSearchParams] = useSearchParams();

  const { getLoginStatusOfMarketplace } = usePlatformLoginStatusCheck();

  useQueryParamsForDatagrid({
    datagrid: 'orders',
    searchValue,
    ordersFilterModel,
    ordersPage: page,
    ordersRowsPerPage: rowsPerPage,
    ordersSortModel: sortModel,
  });

  useEffect(() => {
    dispatch(getTagsRequest());
    dispatch(getGroupsRequest());
  }, []);

  // Handles page change and fetchs new data from the API.
  const handlePageChange = (pageNumber: number) => {
    searchParams.set('page', (pageNumber + 1).toString());
    setSearchParams(searchParams);
    dispatch(setPage(pageNumber));
    dispatch(setLoadingEffect(true));
    const orderBy = sortModel.length > 0 ? sortModel[0].field : 'added';
    const order = sortModel.length > 0 ? sortModel[0].sort : 'desc';
    dispatch(
      ordersGetListRequest({
        page: pageNumber,
        rowsPerPage,
        orderBy,
        order,
        filter: getFilterModelShaped(ordersFilterModel),
        searchValue,
      })
    );
  };

  // Handles the sort model changes and fetchs new data from the API.
  const handleSortModelChange = (model: GridSortModel) => {
    dispatch(setSortModel(model));

    dispatch(setLoadingEffect(true));

    const orderBy = model.length > 0 ? model[0].field : 'added';
    const order = model.length > 0 ? model[0].sort : 'desc';
    searchParams.set('order', order as string);
    searchParams.set('orderBy', orderBy);
    setSearchParams(searchParams);

    dispatch(
      ordersGetListRequest({
        page,
        rowsPerPage,
        orderBy,
        order,
        filter: getFilterModelShaped(ordersFilterModel),
        searchValue,
      })
    );
  };

  const handleFilterModelChange = (model: GridFilterModel) => {
    dispatch(setOrdersFilterModel(model));
    const queryFilterModel = {
      ...model,
      items: model.items.filter((item) => queryFilterItemControl(item)),
    };

    if (dataGridFilterControlsForRequest(model)) {
      dispatch(setPage(0));
      const [sortField, sort] =
        sortModel.length > 0 ? [sortModel[0].field, sortModel[0].sort] : ['added', 'desc'];

      const sortOptions = { orderBy: sortField, order: sort };

      const shouldAddFilterToQuery = model.items.length && queryFilterModel.items.length;
      if (shouldAddFilterToQuery) {
        searchParams.set('filters', JSON.stringify(queryFilterModel));
        setSearchParams(searchParams);
      } else {
        searchParams.delete('filters');
        setSearchParams(searchParams);
      }

      dispatch(
        ordersGetListRequest({
          page: 0,
          rowsPerPage,
          filter: getFilterModelShaped(model),
          searchValue,
          ...sortOptions,
        })
      );
    } else {
      if (queryFilterModel.items.length) {
        searchParams.set('filters', JSON.stringify(queryFilterModel));
        setSearchParams(searchParams);
      } else {
        searchParams.delete('filters');
        setSearchParams(searchParams);
      }
    }
  };

  const filteredData = useCallback(() => {
    const uniqueOrdersData = orders.filter(
      (item, index, self) => self.findIndex((i) => i._id === item._id) === index
    );
    return uniqueOrdersData;
  }, [orders, ordersCount]);

  const dataGridComponentProps: GridSlotsComponentsProps = {
    filterPanel: {
      sx: {
        '& .MuiDataGrid-filterFormValueInput': {
          minWidth: 'max-content !important',
        },
      },
    },
    row: {
      onMouseOver: (event: any) => {
        const currentRow: any = event.currentTarget;
        const id: string = currentRow.getAttribute('data-id');
        const quickMenu = document.querySelector(
          `[data-quick-menu-id="${id}"]`
        ) as HTMLElement | null;
        if (quickMenu) {
          quickMenu.style.display = 'flex';
        }
      },
      onMouseLeave: (event: any) => {
        const currentRow: any = event.currentTarget;
        const id: string = currentRow.getAttribute('data-id');
        const quickMenu = document.querySelector(
          `[data-quick-menu-id="${id}"]`
        ) as HTMLElement | null;

        if (quickMenu) {
          quickMenu.style.display = 'none';
        }
      },
    },
  };

  const dataGridHeight = !isOrderPageInFullscreenMode
    ? 'calc(100vh - 174px)'
    : 'calc(100vh - 32px)';
  return (
    <Box sx={{ height: dataGridHeight }}>
      <DataGridPro
        density={dense ? 'compact' : 'comfortable'}
        loading={loadingEffect}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        page={page}
        checkboxSelection={false}
        isRowSelectable={() => false}
        pageSize={rowsPerPage}
        getEstimatedRowHeight={() => 500}
        getRowHeight={() => 'auto'}
        rowsPerPageOptions={[25, 50, 100, 250, 500]}
        components={{
          Footer: TaskFooter,
          Toolbar: OrdersToolbar,
          NoRowsOverlay: () => <EmptyContent title="No results found." />,
          NoResultsOverlay: () => <EmptyContent title="No results found." />,
          // OpenFilterButtonIcon: () => <Iconify icon="solar:filter-bold" width={24} height={24} />,
          DetailPanelExpandIcon: () => (
            <Iconify icon="eva:chevron-down-fill" width={24} height={24} />
          ),
          DetailPanelCollapseIcon: () => (
            <Iconify icon="eva:chevron-up-fill" width={24} height={24} />
          ),
        }}
        componentsProps={dataGridComponentProps}
        columns={gridColumns()}
        rows={filteredData().map((item) => ({
          id: item._id,
          image: item.thumbnail,
          title: { title: item.title, id: item._id },
          soldPrice: item.soldPrice,
          status: item.status,
          orderedAt: item.orderedAt,
          marketplace: item.marketplace,
          statusText: item.statusText,
          currency: item.currency,
          orderLink: item.orderLink,
        }))}
        rowCount={ordersCount}
        sortModel={sortModel}
        initialState={{
          sorting: {
            sortModel: [{ field: 'added', sort: 'desc' }],
          },
        }}
        headerHeight={dense ? 59 : 43.6}
        getDetailPanelContent={({ row }) => <OrderDetailPanel row={row} />}
        getDetailPanelHeight={() => 'auto'}
        onPageChange={(pageNumber: number) => handlePageChange(pageNumber)}
        onSortModelChange={(model: GridSortModel) => handleSortModelChange(model)}
        onFilterModelChange={(model: GridFilterModel) => {
          handleFilterModelChange(model);
        }}
        sx={{
          '& .MuiDataGrid-cell': {
            whiteSpace: 'normal !important',
            wordBreak: 'break-word !important',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { px: '3px', py: '0px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { p: '6px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            px: '13px',
            py: '7.5px',
          },
          '& .MuiDataGrid-detailPanel': {
            backgroundColor: 'background.default',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
        }}
      />
    </Box>
  );
}
