import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material';

import Iconify from 'src/components/iconify';

import React, { ChangeEvent, useEffect, useState } from 'react';
import { IRootState } from 'src/store';
import { GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { getFilterModelShaped } from 'src/pages/dashboard/Inventory/helpers/getFilterModelShaped';
import { LoadingButton } from '@mui/lab';
import { useSearchParams } from 'react-router-dom';
import useResponsive from 'src/hooks/useResponsive';
import {
  ordersGetListRequest,
  setIsInFullscreenMode,
  setPage,
  setSearchValue,
} from 'src/store/dashboard/slices/ordersSlice';

interface Marketplace {
  name: string;
  icon: string;
  isSupported: boolean;
}

const marketplaces: Marketplace[] = [
  {
    name: 'Poshmark',
    icon: '/icons/png/poshmark.png',
    isSupported: true,
  },
  {
    name: 'Mercari',
    icon: '/icons/png/mercari.png',
    isSupported: true,
  },
  {
    name: 'eBay',
    icon: '/icons/png/ebay.png',
    isSupported: false,
  },
  {
    name: 'Etsy',
    icon: '/icons/png/etsy.png',
    isSupported: false,
  },
  {
    name: 'Depop',
    icon: '/icons/png/depop.png',
    isSupported: false,
  },
  {
    name: 'Facebook',
    icon: '/icons/png/facebook.png',
    isSupported: false,
  },
];

export default function OrdersToolbar() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    page,
    rowsPerPage,
    sortModel,
    ordersFilterModel,
    searchValue,
    isOrderPageInFullscreenMode,
    isRefreshLoading,
  } = useSelector((state: IRootState) => state.orders);

  const onChangeTimer = React.useRef<any>();
  const isMobile = useResponsive('down', 900);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(setSearchValue(e.target.value));

    clearTimeout(onChangeTimer.current);

    onChangeTimer.current = setTimeout(() => {
      const orderBy = sortModel.length > 0 ? sortModel[0].field : 'added';
      const order = sortModel.length > 0 ? sortModel[0].sort : 'desc';
      if (e.target.value.length !== 1) {
        dispatch(
          ordersGetListRequest({
            page: page,
            rowsPerPage: Number(rowsPerPage),
            orderBy,
            order,
            filter: getFilterModelShaped(ordersFilterModel),
            searchValue: e.target.value.trim(),
          })
        );
      }

      if (e.target.value !== '') {
        searchParams.set('search', e.target.value.trim());
        setSearchParams(searchParams);
      } else {
        searchParams.delete('search');
        setSearchParams(searchParams);
      }
    }, 1000);

    if (page !== 0) {
      dispatch(setPage(0));
    }
  };

  const handleSearchClear = () => {
    const orderBy = sortModel.length > 0 ? sortModel[0].field : 'added';
    const order = sortModel.length > 0 ? sortModel[0].sort : 'desc';
    dispatch(setPage(0));
    dispatch(setSearchValue(''));
    searchParams.delete('search');
    setSearchParams(searchParams);
    dispatch(
      ordersGetListRequest({
        page: page,
        rowsPerPage: Number(rowsPerPage),
        orderBy,
        order,
        filter: getFilterModelShaped(ordersFilterModel),
        searchValue: '',
      })
    );
  };

  const handleFullscreen = () => {
    dispatch(setIsInFullscreenMode(!isOrderPageInFullscreenMode));
  };

  const handleRefresh = () => {
    const orderBy = sortModel.length > 0 ? sortModel[0].field : 'added';
    const order = sortModel.length > 0 ? sortModel[0].sort : 'desc';
    dispatch(
      ordersGetListRequest({
        page,
        rowsPerPage,
        orderBy,
        order,
        filter: getFilterModelShaped(ordersFilterModel),
        searchValue,
        refresh: true,
      })
    );
  };

  const isClearButtonVisible = searchValue.length > 0;

  return (
    <Stack bgcolor="#f3f6f8" justifyContent="center" alignItems="center">
      <Stack
        spacing={1}
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ md: 'center' }}
        sx={{ width: 1, p: 1, pr: 2 }}
      >
        <Stack
          spacing={1}
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ md: 'center' }}
          sx={{ width: 1, p: 1, paddingLeft: '0' }}
        >
          {/* <GridToolbarFilterButton
            sx={{ p: 1, color: '#000000', marginRight: '4px' }}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
          /> */}
          {/* {!isMobile && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                height: '24px !important',
                marginTop: '0 !important',
                width: '1px !important',
                marginRight: '12px !important',
                backgroundColor: '#C4CDD5 !important',
                alignSelf: 'center !important',
              }}
            />
          )} */}
          <TextField
            size="small"
            value={searchValue}
            onChange={handleChange}
            placeholder="Search product name, SKU or internal note..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              maxWidth: !isMobile ? '643.9px' : '100%',
              width: '100%',
              '& .MuiOutlinedInput-root': {
                bgcolor: 'white',
              },
            }}
          />

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
            {isClearButtonVisible && (
              <Button
                variant="soft"
                color="error"
                onClick={handleSearchClear}
                startIcon={<Iconify icon="eva:trash-2-outline" />}
              >
                Clear
              </Button>
            )}
          </Stack>
        </Stack>

        <Stack direction="row" spacing={0.5} alignItems="center">
          <Button
            variant="text"
            className="fullscreenModeButton"
            sx={{
              px: 2,
              color: '#212B36',
              fontSize: '13px',
              width: isMobile ? '100%' : isOrderPageInFullscreenMode ? '147px' : '114px',
              minWidth: isMobile ? '100%' : isOrderPageInFullscreenMode ? '147px' : '114px',
              marginLeft: '0 !important',
              whiteSpace: 'nowrap',
            }}
            onClick={handleFullscreen}
            startIcon={
              !isOrderPageInFullscreenMode ? (
                <Iconify
                  icon="mingcute:fullscreen-fill"
                  width={24}
                  height={24}
                  sx={{
                    marginRight: '0 !important',
                  }}
                />
              ) : (
                <Iconify
                  icon="mingcute:fullscreen-exit-fill"
                  width={24}
                  height={24}
                  sx={{
                    marginRight: '0 !important',
                  }}
                />
              )
            }
          >
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 700,
              }}
            >
              {isOrderPageInFullscreenMode ? 'Exit Fullscreen' : 'Fullscreen'}
            </Typography>
          </Button>

          <LoadingButton
            loading={isRefreshLoading}
            variant="text"
            color="primary"
            onClick={handleRefresh}
            sx={{
              px: 2,
              color: '#212B36',
              width: isMobile ? '100%' : '97px',
              minWidth: isMobile ? '100%' : '97px',
              marginLeft: '4px !important',
            }}
            startIcon={
              <Iconify icon="raphael:refresh" width={24} height={24} marginRight={'0!important'} />
            }
          >
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 700,
              }}
            >
              Refresh
            </Typography>
          </LoadingButton>
        </Stack>

        {!isMobile && (
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              height: '24px !important',
              marginTop: '0 !important',
              width: '1px !important',
              backgroundColor: '#C4CDD5 !important',
              alignSelf: 'center !important',
            }}
          />
        )}

        <Stack
          direction="row"
          spacing={0.5}
          alignItems="center"
          sx={{
            overflowX: 'auto',
            '&::-webkit-scrollbar': { display: 'none' },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            maxWidth: '300px',
            minWidth: '220px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {marketplaces.map((marketplace) => (
            <Tooltip
              key={marketplace.name}
              title={
                marketplace.isSupported ? marketplace.name : `${marketplace.name} (Coming soon)`
              }
            >
              <Box
                component="img"
                src={marketplace.icon}
                sx={{
                  width: 32,
                  height: 32,
                  borderRadius: '50%',
                  border: marketplace.isSupported ? '2px solid #00AB55' : 'none',
                  opacity: marketplace.isSupported ? 1 : 0.5,
                  filter: marketplace.isSupported ? 'none' : 'grayscale(100%)',
                  minWidth: 32,
                }}
              />
            </Tooltip>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
}
